@import "@/scss/_global";

.data-table-alternate {
  .page-actions {
    margin-bottom:0.5rem;
  }
  .el-table th.el-table__cell>.cell,
  .el-table td.el-table__cell>.cell {
    padding-right:5px;
    padding-left:5px;
  }
  .el-table--mini th.el-table__cell.has-applied-filter {
    background: rgba(64, 158, 255, 0.5);
  }

  .el-table .el-table-column--selection .cell {
    padding-left: 0;
    padding-right: 0;
    text-align: center;

    .el-checkbox__input {

    }
  }
  .el-table__empty-block {
    padding-left:1.25em;
    justify-content: normal;
    text-align: initial;
    -webkit-box-align: initial;
    -ms-flex-align: initial;
    align-items: normal;
    font-size: 1.25em;
    font-weight: bold;
  }
  .el-table--scrollable-x .el-table__empty-block {
    padding-left:0;
  }
  .filters-clear {
    text-align: center;
  }

  .table-card {
    margin-top: 0 !important;

    .el-card__body {
      padding: 0;
    }
  }

  table .el-select .el-input__inner {
    height: 23px !important;
  }

  .el-select-dropdown__item {
    padding: 0px 10px;
  }

  .cell-content {
    .editable-icon {
      position: absolute;
      right: 5px;
      opacity: 0;
      top: 0;

      el-icon-edit {
        font-size: 12px;
      }
    }

    .el-input--small .el-input__inner {
      height: 23px;
    }
  }

  .cell-content:hover {
    .editable-icon {
      opacity: 1;
    }

    cursor: pointer;
  }

  .finalized-bids .cell-content:hover,
  .closed-bids .cell-content:hover {
    cursor: default;
  }

  .cell-input {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;

    .el-input__inner {
      height: 27px;
    }
  }

  .el-dialog {
    top: 3em;
    bottom: 3em;
    left: 3em;
    right: 3em;
    width: auto;
    overflow: auto;
    position: fixed;
    margin: 0;

    .bottom-row {
      text-align: right;
      padding-top: 60px;
    }
  }

  .el-dialog__body {
    padding-bottom: 60px;
  }

  .list-group {
    width: 100%;
    min-height: calc(100%);
    border: 1px solid rgba(206, 206, 206, 0.5);
    list-style-type: none;
    counter-reset: css-counter 0;

    .list-group-item {
      padding: 0.25rem 0.5rem;
      border-bottom: 1px solid rgba(206, 206, 206, 0.5);
      cursor: pointer;
      counter-increment: css-counter 1;

      span {
        background: purple;
        border-radius: 5px;
        display: inline-block;
        float: right;
        color: white;
        font-size: 0.85em;
        padding: 0.25em 0.5em;
      }

      .has-filter {
        span {
          visibility: hidden;
        }

        &:hover {
          span {
            visibility: visible;
          }
        }
      }

      span.cat-bid_management {
        background: green;
      }

      span.cat-controlling {
        background: darkorange;
      }

      span.cat-engineering {
        background: darkslateblue;
      }

      span.cat-project {
        background: darkred;
      }

      span.cat-general {
        background: teal;
      }
    }

    .list-group-item:before {
      content: counter(css-counter) '. ';
    }

    .list-group-item:last-child {
      border-bottom: none;
    }

    .sortable-chosen {
      padding: 0.25rem 1rem;
      background: rgba(206, 206, 206, 0.5);
    }
  }

  .add-all,
  .remove-all,
  .refresh-fields {
    float: right;
    cursor: pointer;
    margin-top: -2em;
  }

  .refresh-fields {
    margin-right: 5em;
    margin-top: -2.5em;
  }

  .pagination-row {
    margin-top: 1em;

    .setting {
      cursor: pointer;
      height: 28px;
      line-height: 28px;
      width: 28px;
      padding: 0;
    }

    .el-pagination {
      margin-top: 0;
    }

    .export {
      cursor: pointer;
      height: 28px;
      line-height: 28px;
      padding: 0 15px;
    }
  }

  .el-checkbox {
    display: block;
    margin: 0;
    padding: 0;

    .el-checkbox__input {
      float: right;
    }

    .el-checkbox__label {
      padding: 0;
    }
  }

  .el-checkbox:before,
  .el-checkbox:after {
    clear: both;
    content: '';
    height: 0;
    line-height: 0;
    overflow: hidden;
    display: block;
  }

  h4 {
    padding: 0;
    font-size: 1em;
    margin: 0 0 1em 0;
  }

  .header-search {
    min-height: 24px;
    .el-select {
      width: 100%;
    }
    .el-input {
      .el-input__inner {
        height: 23px;
      }
    }

    select {
      width: 100%;
      display: block;
      border-radius: 3px;
      border: 1px solid #dde0e5;
    }

    .el-select__input input {
      margin-left: 5px;
      cursor: pointer;
    }

    select[disabled] {
      visibility: hidden;
    }

    span.col-label {
      display: block;
      margin: 0.5em;
    }

    .el-input__suffix {
      display: block;
      margin: 0;
      padding: 0;
      position: absolute;

      .el-input__suffix-inner {
        display: block;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 0;

        .el-input__icon {
          line-height: 23px;
        }
      }
    }

    .el-select + span {
      display: block;
      margin: 0;
    }

    .el-select-dropdown__item {
      padding-right: 50px;
    }

    .el-select-dropdown__item.selected::after {
      right: 6px;
      top: 10px;
    }

    .el-select__tags {
      top: auto;
      transform: initial;
      bottom: -1px;
      width: 100%;
      max-width: 100% !important;
    }

    .el-select__tags > span {
      display: none;
    }
  }

  .header-search.loading .el-select {
    visibility: hidden;
  }

  .revision-card-wrapper {
    .toggle-button {
      float: right;
    }

    margin-top: 1em;

    .toggle {
      padding: 0.5em 0.5em 0.5em 1em;
      line-height: 35px;
      font-size: 15px;
      border: 1px solid #d1dbe5;
      border-radius: 4px;
      background: #fff;
      overflow: hidden;
    }
  }

  .revision-card .el-card__header {
    display: none;
  }

  .revision-card .el-card__body .el-table__body-wrapper {
    max-height: 49vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .hide-revisions {
    .revision-card {
      display: none;
    }
  }

  .native-table-outer-row {
    position: relative;

    .native-table-outer {
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      background-color: #fff;
    }
  }

  .show-revisions .native-table-outer-row {

  }

  .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: auto;
  }

  .text-right {
    text-align: right;
  }

  .question-dialog .el-dialog {
    width: auto;
    max-width: 640px;
    margin: 0 auto;
    height: auto;
    top: 3em;
    bottom: auto;

    .el-dialog__body {
      padding-bottom: 30px;
    }

    .el-checkbox .el-checkbox__input {
      float: none;
      margin-right: 10px;
    }
  }

  .bulk-actions {
    margin-bottom: 5px;
    float: left;
  }

  .bid-add-button {
    float: right;
  }
}

.header-search-with-groups {
  .el-select-group__wrap.other-filters {
    .el-select-dropdown__item {
      opacity: 0.35;
    }

    .el-select-dropdown__item.selected,
    .el-select-dropdown__item.hover {
      opacity: 0.5;
    }
  }
}
