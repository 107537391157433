@import "@/scss/_global";

.header {
  height: 38px;

  .title {
    padding: 10px;
    background: #324157;
    color: white;
    text-align: center;
  }
}
