@import "@/scss/_global";

.project-single-overview {
  .cell {
    width: inherit !important;
  }

  .el-tag {
    float: right;
  }

  .lerfs {
    margin-top: 1rem;
  }

  .project-details {
    .key {
      text-align: right;
      font-weight: bold;
    }

    .el-row {
      + .el-row {
        margin-top: 0.5rem;
      }
    }
  }

  .el-card {
    + .el-card {
      margin-top: 1.5rem;
    }
  }
  .planned-vs-realized {
    td,
    th {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        text-align: right;
      }
    }
  }
}
