@import "@/scss/_global";

.position-validate-subcontractor {
  .validated-badge {
    font-size: 0.8em;
    padding:0.1em 1em;
    color: #FFF;
    background-color: #F56C6C;

    &.validated {
      background-color: #b3e19d;
      border-color: #b3e19d;
    }
  }

  .position-validation-dialog {
    .el-dialog {
      top: 30px;
      bottom: 30px;
      left: 30px;
      right: 30px;
      width: auto;
      overflow: auto;
      position: fixed;
      margin: 0;

      &.is-locked {

      }
      .bottom-row {
        text-align: right;
        padding-top: 60px;
      }

      .actions-row {
        padding-top: 20px;
      }

    }
    .el-dialog__body h4 {
      margin-top:0;
    }
    .el-dialog__footer .el-button {
      padding:8px 20px;
    }
    .el-dialog__header {
      h3 {
        margin: 0;
      }
    }
  }
  .position-images {
    margin-top: 15px;
    height:285px;
    overflow: auto;

    h2 {
      display: none;
    }
  }
}
