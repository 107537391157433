@import "@/scss/_global";

.info-small {
  font-size: 14px;
  color: #666;
}

.project_field {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 16px;

  &:last-child {
    border: none;
  }

  h3 {
    margin: 0;
    font-size: 16px;
  }

  p {
    font-size: 12px;
    color: #666;
    margin: 0 0 5px 0;
  }

  &--show {
    display: flex;

    .name {
      // min-width: 150px;
      width: 50%;
      padding-right: 10px;
    }
  }
}
.fields_group {
  border-bottom: 2px solid #eeeeee;
  margin-bottom: 15px;

  > h3 {
    margin: 0;
    font-size: 1.3rem;
  }

  .group_items {
    display: flex;


    .el-date-editor,
    .el-select {
      width: 100%;
    }
  }

  &--count_1 {
    .project_field {
      width: 100%;
    }
  }
  &--count_2 {
    .project_field {
      width: 50%;
    }
  }
  &--count_3 {
    .project_field {
      width: 33.333%;
    }
  }
  .project_field {
    flex-direction: column;
    border: none;

    .name {
      padding:0;

      h3 {
        font-size: 15px;
      }
    }
    .value {
      margin-top: auto;
      padding-top: 5px;
    }

    &--show {
      .name {
        width: auto;
      }
    }
  }
  .project_field:nth-child(odd) {
    border: 1px solid #eee;
  }
}
