@import "@/scss/_global";

::v-deep .lerfs-reporting-table {
  .bottom-row {
    margin-top: 1em;
  }

  th {
    vertical-align: middle;
  }

  .day-9 {
    background: rgba(#ffdc00, 0.4) !important;
  }

  .day-8 {
    background: rgba(#ff4136, 0.4) !important;
  }

  .day-7 {
    background: rgba(#2ecc40, 0.4) !important;
  }

  .day-6 {
    background: rgba(#b10dc9, 0.4) !important;
  }

  .day-5 {
    background: rgba(#001f3f, 0.4) !important;
    color: #fff;
  }

  .day-4 {
    background: rgba(#ff851b, 0.4) !important;
  }

  .day-3 {
    background: rgba(#0074d9, 0.4) !important;
  }

  .day-2 {
    background: rgba(#7fdbff, 0.4) !important;
  }

  .day-1 {
    background: rgba(#01ff70, 0.4) !important;
  }
}
