@import "@/scss/_global";

.table-claim-items {
  .el-table {
    width: 100%;
  }

  .el-table td,
  .el-table th {
    height: 30px;

    div.cell {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
