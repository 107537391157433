@import "@/scss/_global";

.text-right {
  text-align: right;
}

table {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;

  tbody tr td {
    height: 33px;
  }

  thead tr th,
  tbody tr td {
    padding: 1px 4px 2px 4px;
  }

  &.summary {
    width: 300px;
    margin-bottom: 20px;

    tbody tr td {
      height: 22px;
      padding: 0;
    }
  }

  &.position-items {
  }
}
