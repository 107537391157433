@import "@/scss/_global";

.dates-filter {
  display: flex;
  align-items: center;
}

.date-filter {
  margin-right: 10px;
}

.search-button {
  margin-bottom: 20px;
}

::v-deep .claims-reporting-table {
  .bottom-row {
    margin-top: 1em;
  }

  th {
    vertical-align: middle;
  }

  th .cell {
    vertical-align: middle;
  }

  .day-9 {
    background: rgba(#ffdc00, 0.4) !important;
  }

  .day-8 {
    background: rgba(#ff4136, 0.4) !important;
  }

  .day-7 {
    background: rgba(#2ecc40, 0.4) !important;
  }

  .day-6 {
    background: rgba(#b10dc9, 0.4) !important;
  }

  .day-5 {
    background: rgba(#001f3f, 0.4) !important;
    color: #fff;
  }

  .day-4 {
    background: rgba(#ff851b, 0.4) !important;
  }

  .day-3 {
    background: rgba(#0074d9, 0.4) !important;
  }

  .day-2 {
    background: rgba(#7fdbff, 0.4) !important;
  }

  .day-1 {
    background: rgba(#01ff70, 0.4) !important;
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background: none;
  }

  .el-table--enable-row-hover .el-table__body tr:hover {
    background: mix(#000, #fff, 20%);
  }
}
