@import "@/scss/_global";

::v-deep .el-dialog__headerbtn {
  position: absolute;
  right: 20px;
  top: 20px;
}

.danger {
  color: $danger;
}

.body {
  min-height: 200px;
  line-height: 1.5;
}
