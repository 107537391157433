@import "@/scss/_global";

@import '~scss/mixins';

@include tiny-vertical-scroll;

::v-deep .el-card__body {
  margin: 0 -10px;

  .el-row {
    font-size: 14px;
    line-height: 30px;
    padding: 0 10px;

    &.item:hover {
      background: rgba(15, 126, 190, 0.2);
    }

    &.item .el-button {
      visibility: hidden;
    }

    &.item:hover .el-button {
      visibility: visible;
    }
  }

  .el-radio__label {
    padding-left: 12px;
  }
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 400px);
  padding-right: 2px;
  margin-right: -1px;
}

.sub-header,
.content {
  float: none;

  &:not(.addition-input) {
    width: unset;
  }

  &.addition-input {
    margin-left: -3px;
    margin-right: 25px;
  }

  ::v-deep input {
    height: auto;
    font-size: 14px;
    line-height: 22px;
  }

  &.sort {
    width: 62px;
    text-align: right;
  }
}

.addition-input,
.content.label {
  flex-grow: 1;
}

.content.label {
  cursor: pointer;
}

.addition-icon {
  color: #95f204;
  min-width: 30px;
}

.spacer {
  height: 5px;
}
