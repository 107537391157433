@import "@/scss/_global";

::v-deep .reporting-table {
  .bottom-row {
    margin-top: 1em;
  }

  th {
    vertical-align: middle;
  }

  th .cell {
    vertical-align: middle;
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background: none;
  }

  .el-table--enable-row-hover .el-table__body tr:hover {
    background: mix(#000, #fff, 20%);
  }
}
