@import "@/scss/_global";

.el-header {
  padding: 0;
  margin: 0;
  height: initial!important;
  h4 {
    margin-top: 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
.el-row + .el-row {
  margin-top: 3em;
}
