@import "@/scss/_global";

.bid-finalize-container {
  .text-right {
    text-align: right;
  }

  .into {
    margin-bottom: 30px;
  }

  .el-tabs {
    border-bottom: 1px solid #d1dbe5;
    padding-bottom: 30px;

    label {
      display: block;
      margin-bottom: 5px;
    }
  }

  .search-results {
    min-height: 150px;
    margin-top: 30px;
    max-height: 300px;
    overflow-y: auto;

    .heading {
      font-weight: bold;
      margin-bottom: 30px;
    }

    table {
      font-size: 80%;

      tr {
        background-color: #fff;

        th,
        td {
          text-align: left;
          padding: 3px 10px;
        }
      }

      tr:nth-child(2n) td {
        background: #f7f7f7;
      }
    }

    .overwrite {
      .el-icon-info {
        cursor: pointer;
      }
    }
  }

  .actions-row {
    margin-top: 30px;
  }

  .el-radio {
    margin-right: 10px;
  }

  .el-radio .el-radio__label {
    display: none;
  }

  .selected-project {
    width: 100%;
  }

  .into + label {
    display: block;
    margin-bottom: 5px;
  }
}
