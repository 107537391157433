@import "@/scss/_global";

.text-right {
  text-align: right;
}

.subcontractor-title, .subcontractor-contact-title {
  margin: 0;
}

.subcontractor-title {
  font-size: 20px;
  margin-bottom: 5px;
}

.subcontractor-contact-title {
  font-size: 16px;
  margin-bottom: 5px;
}

table.subcontractor-report {
  margin-bottom: 30px;
  table-layout: fixed;
  width: 1600px;

  > tbody tr:hover td {
    background: lighten($primary, 38);
  }

  tbody tr td {
    height: 33px;
    vertical-align: top;
    line-height: 27px;
  }

  thead tr th, tbody tr td {
    padding: 1px 4px 2px 4px;
  }

  .existing-approval {
    display: inline-block;
    margin: 1px 0;

    > div {
      display: inline-block;
      font-size: 0.9em;

      &:first-child {
        width: 110px;
      }

      &:nth-child(2) {
        width: 220px;
      }

      &:nth-child(3) {
        width: 157px;

      }

      &:nth-child(4) {
        width: 90px;
      }

      &:nth-child(5) {
        width: 52px;
        text-align: right;
      }
    }
  }
}

.subcontractor {
  font-weight: normal;
}

table thead tr.pending-approvals th {
  height: 30px;
  font-size: 0.9em;
}
