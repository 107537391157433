@import "@/scss/_global";

.lerf-email-preview {
  h3 {
    font-size: 1.4rem;
    margin: 0;
  }

  .email-preview {
    .a4paper {
      min-width: 1125px;
    }
    overflow-x: auto;
  }

  @media screen and (max-width:1650px) {
    .saved-version,
    .email-preview {
        width:100%;
    }
    .saved-version {
      margin-top: 25px;
    }
  }

  .saved-version-download-table {
    width:100%;

    caption {
      text-align: left;
      padding:5px;
    }

    tr {
      &:nth-child(even) {
        background: #eff2f7;
      }
      th, td {
        text-align: left;
        &.version {
          width: 40px;
        }
        &.download {
          width: 40px;
          text-align: right;
        }
        &.date {
          width: 80px;
        }
      }
    }
  }

  .el-card {
    margin-bottom: 0.5rem;
  }

  .a4paper {
    // a4wrapper is defined in the template. We render it differently on
    // smaller screens, but have to keep it at a certain width when rendering
    // to pdf
    &,
    .a4wrapper {
      &.landscape {
        width: auto !important;
        max-width: 29.7cm;
      }

      &.portrait {
        width: auto !important;
        max-width: 21cm;
      }
    }

    padding-left: 1.5cm;
    padding-right: 1.5cm;
    border-left: 1px dotted lighten(black, 50%);
    border-right: 1px dotted lighten(black, 50%);
  }

  .vertical-radio {
    display: block;
    margin-bottom: 10px;
  }
}
