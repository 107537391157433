@import "@/scss/_global";

.project-selector {
  width: calc(100% - 1rem) !important;
  margin: 0 0.5rem;

  input {
    border-radius: 0;
  }
}
