@import "@/scss/_global";

.position-select {
  width: 100%;
}

.measurements input,
.calculated input {
  text-align: right;
}
