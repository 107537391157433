@import "@/scss/_global";

.position-images {
  input {
    display: none;
  }
  .picture-modal {
    z-index: 10000000;

    .picture {

    }
  }
  .image-wrapper:before,
  .image-wrapper:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height:0;
    overflow: hidden;
  }
  .image-thumbnail,
  .file-thumbnail {
    width:150px;
    height:150px;
    position: relative;
    float: left;
    box-sizing: border-box;
    border:2px solid transparent;
    overflow: hidden;

    .el-icon-delete {
      position: absolute;
      top:-50px;
      right:-50px;
      border-bottom-left-radius: 8px!important;
      border-top-right-radius: 8px!important;
    }
  }
  .file-thumbnail {
    width:100px;
    height:100px;
  }

  .image-thumbnail > div,
  .file-thumbnail > div {
    border-radius: 8px;
    position: absolute;
    top:5px;
    left:5px;
    right:5px;
    bottom:5px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    background-color: white;

    .el-icon-document {
      height:auto;
      width:auto;
      font-size: 50px;
      display: inline-block;
      margin-top:15px;
    }
    span {
      display: block;
      font-weight: bold;
      font-size: 12px;
    }

  }
  .image-thumbnail:hover,
  .file-thumbnail:hover {
    cursor: pointer;
    > div {
      opacity:0.75;
    }
    .el-icon-delete {
      top:0px;
      right:0px;
    }
  }

}
