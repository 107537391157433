@import "@/scss/_global";

::v-deep .el-dialog {
  // width: 90vh;
  max-height: 90vh;
}
img,
.pdf {
  max-height: 80vh;
  // max-width: 80vw;
}
.pdf {
  width: 100%;
}
