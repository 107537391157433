@import "@/scss/_global";

::v-deep input {
  padding-left:5px!important;
}
.key,
.value {
  height: 36px;
  line-height: 36px;
}

.el-icon-close {
  color: red;
  font-weight: 900;
  padding: 5px;
}

.el-icon-edit {
  padding: 5px;
}

.el-icon-plus {
  padding: 0;
}


li {
  font-size:12px;
  display: grid;
  /*                     DEL   | REF   | DESC |  UNIT | PRICE | FIXED | Hidden | P Index | P Index | B Index | A Index   | ACTIONS  */
  grid-template-columns: 40px    90px    auto    70px   70px    80px    80px     150px                130px     130px       70px;
  grid-gap: 2px;

  align-self: center;
  background: #eef1f6;
  &:nth-child(2n) {
    background: #ffffff;
  }

}
.price-input {
  display: grid;
  grid-gap: 2px;
  /*                     DEL   | REF   | DESC |  UNIT | PRICE | FIXED | Hidden | P Index | P Index | B Index | A Index   | ACTIONS  */
  grid-template-columns: 40px    90px   auto     70px   70px    80px    80px    150px                130px     130px        70px;

  align-self: center;
  align-items: center;
  background-color: rgb(202, 249, 130);
}

.withInternalReference {
  li, .price-input {
    /*                     DEL   | REF   | Soli. REF | DESC |  UNIT | PRICE | FIXED | Hidden | P Index | P Index | B Index | A Index   | ACTIONS  */
    grid-template-columns: 40px   90px     90px        auto    70px   70px    80px    80px     150px               130px     130px       70px;
  }
  &.withNotInvoicable {
    li, .price-input {
      /*                     DEL   | REF   | Soli. REF | DESC |  UNIT | PRICE | FIXED | Hidden | NOT Inv. | P Index | P Index | B Index | A Index   | ACTIONS     */
      grid-template-columns: 40px   90px     90px        auto    70px    70px    80px    80px     70px      150px                130px     130px       70px;
    }
  }
}
.isNativePricingTemplate {
  li, .price-input {
    /*                     DEL   | REF   | DESC |  UNIT | PRICE | FIXED | Hidden | P Index | P Index | B Index | A Index   | ACTIONS  */
    grid-template-columns: 40px  90px     auto    70px    70px    80px    80px      150px     150px     130px     130px       70px;
  }
}
ul {
  padding: 0;
  margin: 0;
}
::v-deep .el-dialog__body div {
  display: flex;
  align-items: center;
  span {
    width: 160px;
  }
  input {
    margin: 5px;
  }
}

.category {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}

::v-deep .el-collapse-item__header {
  background: rgba(46, 143, 244, 0.192156862745098);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  line-height: 30px;
  height: 30px;

  .category > span > span:first-child {
    display: inline-block;
    min-width: 250px;
  }

  .el-tag {
    margin-left: 10px;
  }
}
