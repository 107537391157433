@import "@/scss/_global";

input {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.documents {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
  padding-top: 16px;
}

.document {
  width: 150px;
  height: 150px;
  margin: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:hover {
    border-radius: 8px;
    background-color: #0001;
  }

  small {
    margin-top: 4px;
  }

  .el-icon-delete {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    border-radius: 8px;
    color: red;

    &:hover {
      background-color: #0001;
    }
  }
}

.document-icon {
  font-size: 80px;
  flex: 1;
  display: flex;
  align-items: center;
}
