@import "@/scss/_global";

.purchaseInvoice-edit-item-dialog {
  .line {
    padding-left: 2rem;

    &:first-child {
      padding-left: 0;
    }
  }
}

.position-filter {
  max-width: 50vw;
}
