$primary: #20a0ff;
$secondary: #20a0ff;
$tertiary: #20a0ff;
$forth: #20a0ff;

$black: #1f2d3d;
$light-black: #324157;
$extra-light-black: #475669;

$silver: #8492a6;
$light-silver: #99a9bf;
$extra-light-silver: #c0ccda;

$grey: #d3dce6;
$light-grey: #e5e9f2;
$extra-light-grey: #eff2f7;

$white: #ffffff;
$dark-white: #f9fafc;

$size_h1: 2rem;
$size_h2: 1.8rem;
$size_h3: 1.6rem;
$size_h4: 1.4rem;
$size_h5: 1.3rem;
$size_h6: 1.2rem;

$steel-blue: #499cb7;

$z_indices: body, table, top-bar, menu;

$sidebar-width: 15rem;
$topbar-height: 3rem;
$body-padding: 16px;

$blue: #20a0ff;
$success: #13ce66;
$warning: #f7ba2a;
$danger: #ff4949;
