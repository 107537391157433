@import "@/scss/_global";

.items-report-overview {
  font-size: 12px;
  margin-bottom: 30px;

  .text-right {
    text-align: right;
  }

  table td {
    padding: 0;
  }

  .header {
    font-size: 13px;
    margin: 10px 0 5px 0;

    .street,
    .area,
    .executor,
    .comments {
      line-height: 1.5;
      font-weight: bold;

      > span {
        display: inline-block;
        width: 100px;
      }
    }

    .comments {
      font-weight: normal;
    }
  }

  .imported-row {
    // If we can toggle the row on this column, show it for the whole row
    .allow-importing & {
      background-color: #2a8b2d;

      &:hover {
        background-color: lighten(#2a8b2d, 5%);
      }
    }

    // Always show it on the imported row
    .imported-cell {
      background-color: #2a8b2d;
    }

    &:hover {
      .imported-cell {
        background-color: lighten(#2a8b2d, 5%);
      }
    }
  }

  .not-imported-row {
    // If we can toggle the row on this column, show it for the whole row
    .allow-importing & {
      background-color: #e29090;
      border-left: 2px solid #e29090;
      border-right: 2px solid #e29090;

      &:hover {
        background-color: lighten(#e29090, 5%);
      }
    }

    // Always show it on the imported row
    .imported-cell {
      background-color: #e29090;
    }

    &:hover {
      .imported-cell {
        background-color: lighten(#e29090, 5%);
      }
    }
  }

  .allow-marking {
    .invoice-status-row {
      background-color: #2a8b2d;
      border-left: 2px solid #2a8b2d;
      border-right: 2px solid #2a8b2d;

      &:hover {
        background-color: lighten(#2a8b2d, 5%);
      }
    }

    .not-invoice-status-row {
      background-color: #e29090;
      border-left: 2px solid #e29090;
      border-right: 2px solid #e29090;

      &:hover {
        background-color: lighten(#e29090, 5%);
      }
    }
  }

  .items-report-overview {
    padding-bottom: 25px;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    td,
    th {
      padding: 2px 4px;
    }

    th {
      text-align: left;
      border-bottom: 2px solid $black;
    }

    tr.comments {
      border-bottom: 2px solid #777 !important;
      border-right: 2px solid #777 !important;
      border-left: 2px solid #777 !important;
      font-style: italic;
    }
  }

  .can-toggle tbody tr {
    cursor: pointer;
  }

  // Stupid checkboxes double the height of rows
  .el-form-item {
    margin-bottom: 0;
  }

  .el-checkbox,
  ::v-deep .el-form-item__content {
    line-height: 18px;
  }

  .filebox {
    border: 1px solid grey;

    a {
      display: flex;
      width: 200px;
      height: 100%;
      padding: 10px;
      text-align: center;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    .fa {
      font-size: 30px;
    }
  }
}
