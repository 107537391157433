@import "@/scss/_global";

.reset-button,
.quick-select-button {
  padding: 7px 12px;
}

.quick-select-button {
  background: rgb(174, 32, 255);
  color: white;

  &:hover {
    background: rgba(174, 32, 255, 0.8);
    border-color: rgba(174, 32, 255, 0.9);
  }
}

.approval-details,
.approved-items,
.available-items {
  margin-top: 1em;
  font-size: 0.9em;
  line-height: 1.3;

  ::v-deep .el-card__header {
    font-weight: bold;
    color: rgb(50, 65, 87);
  }
}

.reference {
  font-size: 1.5em;
}

.subcontractor {
  font-size: 1.3em;
}

.approval-title {
  font-size: x-large;
  text-align: center;
}

.approval-meta {
  text-align: right;
}

.status {
  text-align: right;
}

::v-deep .approved-items,
::v-deep .available-items {
  .cell {
    padding-left: 5px;
    padding-right: 5px;
  }

  td,
  th {
    height: 30px;
  }

  .el-table {
    margin-top: 5px;
  }

  .el-button-group {
    margin-left: 5px;
  }

  .intro {
    padding-top: 1em;

    h2 {
      font-size: 16px;
      margin: 10px 0 0 0;
    }

    h3 {
      font-size: 14px;
      margin: 5px 0 0 0;
    }
  }

  .el-button--mini {
    height: 24px;
  }
}

::v-deep .approval-rejected {
  td {
    background: lighten(rgba(255, 73, 73, 0.5), 10);
  }
}

::v-deep .approval-approved {
  td {
    background: lighten(rgba(19, 106, 102, 0.5), 20);
  }
}

el-table__row approval-approved ::v-deep .imported-item {
  td {
    color: #555;
    background: #eee;
  }
}
