@import "@/scss/_global";

::v-deep .el-tabs__content {
  width: 100%;
  overflow-x: auto !important;

}

::v-deep .el-tab-pane {
  min-width: 1250px;
}

.file-input {
  display: none;
}

#template-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.el-tabs {
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table-borderless {
  width: 100%;

  tr {
    td {
      padding: 3px 5px 3px 0;
      font-size: 14px;

      &:first-child {
        font-weight: bold;
        width: 250px;
      }

      &:last-child {
        // width: 99%;
        text-align: right;
      }
    }
  }
}

.place-buttons {
  position: relative;
  z-index: 100;
  top: 54px;
  right: 10px;
}

.add-category {
  display: flex;
  align-items: center;
}

.add-category .el-input {
  width: 200px;
}

.add-category .el-button {
  margin: 10px 0px 10px 10px;
}

::v-deep .el-collapse-item__content {
  padding: 0px;
}

// I think you do not want to set this to a fixed width??
::v-deep .el-tabs__item {
  width: 100px;
}


table.default {
  width: 100%;

  tr {
    background: initial;

    th,
    td {
      text-align: initial;
      padding: 5px;
      background: initial;
    }

    th {
      font-size: 1.1em;
    }
  }

  tr:nth-child(odd) {
    background: #eef1f6;
  }
}


