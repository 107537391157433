@import "@/scss/_global";

.lerf-edit-item-dialog {
  ::v-deep .position-select {
    width: 100%;
  }

  ::v-deep .measurements input,
  ::v-deep .calculated input {
    text-align: right;
  }
}
