@import "@/scss/_global";

::v-deep .el-form-item {
  margin-bottom: 14px;
}
::v-deep .el-form-item__label {
  padding: 0 10px 0 0;
  line-height: 28px;
}
::v-deep .el-form-item__content {
  line-height: 24px;
}
::v-deep .el-input input {
  height: 28px;
}
