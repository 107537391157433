@import "@/scss/_global";

.actions {
  text-align: right;
  display: block;
  padding-right: 5px;
}

::v-deep .el-input {
  padding-right: 0px;
}

::v-deep .el-select {
  padding-right: 0px;

  .el-input {
    padding-right: 0;

    i {
      margin-right: 0px;
    }
  }
}

::v-deep input {
  margin-left: 0;
}

::v-deep .el-input {
  width: calc(100% - 10px);
}

.el-button {
  padding: 0px;
  height: 24px;
  width: 60px;
}

.el-form {
  height: 30px;
}

::v-deep .el-form-item {
  margin-bottom: 0px!important;
}

::v-deep .el-icon-plus {
  padding: 0 0 0 15px;
}


::v-deep .el-input {
    .el-input__inner {
    height: 24px;
  }
}

::v-deep .el-collapse-item__content {
  padding: 0px;
}

::v-deep .el-form-item__content {
  line-height: 1.7;
}
