@import "@/scss/_global";

h2 {
  font-size: 16px;
  margin: 20px 0 5px 0;
}

.column {
  display: flex;
  flex-direction: column;
}

::v-deep.el-radio {
  margin-left: 0px;
  line-height: 30px;
}

.el-button {
  margin-top: 10px;
}
