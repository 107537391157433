@import "@/scss/_global";

.purchase-invoice-add-dialog {
  .el-select {
    width: 100%;
  }
}
.project-hidden {
  display: none;
}
