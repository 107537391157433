@import "@/scss/_global";

@import '~scss/mixins';

.comp-projects-tab {
  @include tiny-vertical-scroll;

  .scrollable {
    overflow-y: auto;
    height: calc(100vh - 254px);
    padding-right: 10px;
    margin-right: -1px;
  }

  .error {
    color: red;
  }

  .el-radio__label {
    display: none;
  }

  .item-container {
    margin: -20px -20px;
    padding: 10px 0;
  }

  .item {
    font-size: 14px;
    padding: 0 5px;

    > div > div {
      padding: 5px 10px;
      line-height: 22px;
    }

    &.selectable:not(.disabled):hover {
      cursor: pointer;
      background: rgba(15, 126, 190, 0.2);
    }

    &.selectable.disabled:hover {
      cursor: not-allowed;
    }
  }
}
