@import "@/scss/_global";

$gutter: 15px;
$columns: 5;

.multiple-picker {
  .el-checkbox {
    display: inline-block;
    // This makes sure that $columns amount of images fit on exactly 100% of the screen
    width: calc(
      calc(100% / $columns) - calc($gutter * ($columns - 1) / $columns)
    );
    overflow: hidden;
    margin-bottom: $gutter;

    // Trick to cut off images at a 4:3 ratio, regardless of their size
    &:before {
      content: '';
      display: block;
      margin-bottom: calc(75% - 18px);
      transition: 0.25s linear ease;
    }

    // We can optionally expand the items that are cut off on hover, but I don't like the
    // look of it.
    // &:hover {
    //   .el-checkbox__label * {
    //     position: relative;
    //   }

    //   &:before {
    //     margin-bottom: 0;
    //   }
    // }

    .el-checkbox__input {
      position: absolute;
      top: 0;
      left: 0;
    }

    .el-checkbox__label {
      * {
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }

    // This resets the margin at the beginning of a row without making explicit rows
    &:nth-child(#{$columns}n + 1) {
      margin-left: 0;
    }
  }
}
