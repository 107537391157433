@import "@/scss/_global";

.core-topbar {
  background: rgb(50, 65, 87);
  z-index: 4;
  .el-button {
    margin: 0;
    padding: 0;
  }
  .el-button + .el-button {
    margin-left: 10px;
  }
  .el-icon {
    color: #eee;
    font-size: 24px;
    font-weight: normal;
  }

  .core-header {
    padding: 0 10px 0 260px;
    color: #eee;
    line-height: 49px;
    vertical-align: middle;
    h1 {
      font-size: 1rem;
      line-height: 1;
      display: inline-block;
      margin: 0px;
      text-transform: uppercase;
      vertical-align: middle;
    }

    .title-tag {
      margin-left: 1rem;
      display: inline-block;
      background: #8391a5;
      color: #ffffff;
      vertical-align: middle;
      line-height: 32px;
      height: 32px;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .buttons-right {
    top: 50%;
    transform: translateY(-50%);
    right: 2rem;
    position: absolute;
  }

  .fa-exclamation-triangle {
    color: darken(red, 15%);
  }
}
