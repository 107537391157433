@import "@/scss/_global";

.email-image-picker {
  margin-bottom: 0.5rem;

  h3 {
    font-size: 1.4em;
    margin: 0;
  }
}
