@import "@/scss/_global";

@import '~scss/variables';
@import '~scss/mixins';
@include tiny-vertical-scroll;


.sidebar {
  background: $light-black;

  ::v-deep .el-menu {
    background-color: $light-black;
    border-right: none;

    &.main-menu {
      height:calc(100vh - 140px);
    }
  }

  ::v-deep .el-menu-item:hover,
  ::v-deep .el-submenu__title:hover {
    background-color: #48576a;
  }
  ::v-deep .el-menu-item:focus,
  ::v-deep .el-submenu__title:focus {
    background-color: transparent;
  }

  ::v-deep .project-selector {
    margin-top: 6px;
    padding: 4px;
  }

  .main-menu {
    overflow-y: auto;
    margin-top: 10px;

    ::v-deep .el-submenu__title,
    ::v-deep .el-menu-item {
      color: #bfcbd9;
      padding: 0 40px;
    }
    ::v-deep .el-menu-item.is-active {
      color: $blue;
    }
  }
  ::v-deep .el-badge__content {
    border:none;
  }
  ::v-deep .el-badge__content.is-fixed {
    position: initial;
    -webkit-transform: translateY(-50%) translateX(0);
    transform: translateY(-50%) translateX(0);
  }



  .logo {
    width: 100%;
  }

  .el-form {
    margin-left: 0;

    .bottom-row {
      display: none;
    }
  }

  ::v-deep .el-menu-item,
  ::v-deep .el-submenu__title {
    height: 35px;
    line-height: 35px;
  }
}
