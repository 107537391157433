@import "@/scss/_global";

.bid-edit-container {
  .el-breadcrumb {
    margin-bottom: 15px;
  }
  .bid-field {
    .el-row {
      padding: 15px;
      border-bottom: 1px solid #eff2f7;
    }

    .field-label {
      label {
        font-weight: bold;
        display: block;
      }

      small {
        display: block;
      }
    }

    .bid-field-hide,
    .cell-input {
      display: none;
    }

    .bid-field-hide.has-value {
      display: block;
    }
  }

  .is-editing {
    .bid-field {
      .bid-field-hide,
      .cell-input {
        display: block;
      }
    }
  }

  .bid-field + .el-row {
    margin-top: 30px;
  }

  .text-right {
    text-align: right;
  }

  .revision-card-wrapper {
    .toggle-button {
      float: right;
    }

    margin-top: 1em;

    .toggle {
      padding: 0.5em 0.5em 0.5em 1em;
      line-height: 35px;
      font-size: 15px;
      border: 1px solid #d1dbe5;
      border-radius: 4px;
      background: #fff;
      overflow: hidden;
    }
  }

  .revision-card .el-card__header {
    display: none;
  }

  .revision-card .el-card__body .el-table__body-wrapper {
    max-height: 49vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .hide-revisions {
    .revision-card {
      display: none;
    }
  }

  .show-revisions .native-table-outer-row {
    max-height: 25vh;
    overflow: auto;
  }

  .breadcrumbs-row {
    margin-bottom: 10px;

    .el-col {
      width: auto;
    }
    .el-breadcrumb {
      margin-bottom: 0;
      line-height:22px;
    }
  }

}
