@import "@/scss/_global";

::v-deep .el-table td {
  height: auto;
  padding-top: 2px;
  padding-bottom: 2px;
}
.header-action-row {
  margin-bottom:15px;
}
