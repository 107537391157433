@import "@/scss/_global";

.core-menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: $sidebar-width;
  z-index: z($z_indices, menu);
}

.core-topbar {
  height: $topbar-height;
  top: 0;
  position: sticky;
  z-index: z($z_indices, top-bar);
}

.core-body {
  background-color: $extra-light-grey;
  display: flex;
  flex-direction: column;
  z-index: z($z_indices, body);
}

.menu_visible {
  .core-body {
    margin-left: $sidebar-width;
    padding: $body-padding;
    min-height: calc(100vh - #{$topbar-height});
    // min-height: calc(100vh);
    overflow: auto;
  }
}
