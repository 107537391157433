@import "@/scss/_global";

.project_field {
  display: flow-root;
  /*
  margin: 10px 0;

  background: #fff;
  padding: 15px 10px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;

   */

  .options_row {
    display: flow-root;
  }

  .isinline {
    display: inline-block;
    margin-right: 0px;
  }
  .isinline + label {
    display: inline-block;
    margin-right: 15px;
  }


  label {
    font-size: 14px;
    font-weight: bold;
    color: #666;
    padding-bottom: 3px;
    display: block;
  }
}

.project_field_input {
  float: left;
  /* width: 33%; */
  margin: 0 5px 10px 5px;

  &.small {
    width: 50px;
  }

  &.full {
    clear: both;
    width: 100%;
  }

  &.big {
    width: 400px;
  }
}
