@import "@/scss/_global";

.teams-view {
  .pagination {
    float: right;
    margin-top: 1rem;

    &:after {
      content: '';
      clear: both;
      display: block;
    }
  }
}
