@import "@/scss/_global";

::v-deep .el-card {
  + .el-card {
    margin-top: 1.5rem;
  }
}

::v-deep .table-card {
  margin-top: 3rem;

  .el-card__body {
    padding: 0;
  }
}
