@import "@/scss/_global";

.claim-email-preview {
  h3 {
    font-size: 1.4rem;
    margin: 0;
  }

  .el-card {
    margin-bottom: 0.5rem;
  }

  .a4paper {
    // a4wrapper is defined in the template. We render it differently on
    // smaller screens, but have to keep it at a certain width when rendering
    // to pdf
    &,
    .a4wrapper {
      &.landscape {
        width: auto !important;
        max-width: 29.7cm;
      }

      &.portrait {
        width: auto !important;
        max-width: 21cm;
      }
    }

    padding-left: 1.5cm;
    padding-right: 1.5cm;
    border-left: 1px dotted lighten(black, 50%);
    border-right: 1px dotted lighten(black, 50%);
  }
}
