@import "@/scss/_global";

:root .el-form-item.compact {
  margin-left: -5px;
  margin-right: -5px;

  label {
    position: relative;
    display: block;
  }

  small {
    display: block;
    line-height: 1;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}


.el-input--mini .el-input__inner {
  font-size: 12px;
  line-height: 28px;
  height: 28px;
}
