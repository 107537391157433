@import "@/scss/_global";

@import '~scss/mixins';

@include tiny-vertical-scroll;

.scrollable {
  overflow-y: auto;
  height: calc(100vh - 320px);
  padding-right: 10px;
  margin-right: -1px;
}

::v-deep .el-card__header {
  font-size: 15px;
}

.pagination {
  float: right;
  margin-top: 1rem;

  &:after {
    content: '';
    clear: both;
    display: block;
  }
}

.approvals,
.subcontractors {
  ::v-deep .el-input input {
    height: auto;
  }

  .el-switch {
    margin-right: 4px;
  }
}

.approvals .el-input {
  margin-left: -5px;
}

.approval-list,
.subcontractor-list {
  &.header {
    padding: 5px;
    font-size: 13px;
    border-bottom: 1px solid black;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .approval-row,
  .subcontractor-row {
    padding: 3px 5px;
    font-size: 13px;
    line-height: 24px;
    height: 30px;
    white-space: nowrap;

    div.el-col {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }

    &:nth-child(even) {
      background: rgb(239, 242, 247);
    }

    &:hover {
      background: rgba(32, 160, 255, 0.5);
      cursor: pointer;
    }
  }

  .el-checkbox {
    margin-right: 6px;
  }
}

.el-button {

}

.bottom {
  bottom: 15px;
  position: relative;
}
