@import "@/scss/_global";

.view-single-project-notes {
  h1 {
    font-size: 1.3em;
  }

  h2 {
    font-size: 1.2em;
  }

  h3 {
    font-size: 1.1em;
  }
}
