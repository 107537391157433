@import "@/scss/_global";

.note-editor {
  max-width: 60em;

  .editor {
    width: 100%;
    min-height: 10em;
    background: $white;
    border: 1px solid $black;
    padding: 10px;

    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .save-button {
    float: right;
    margin-top: 5px;
  }

  &:after {
    clear: both;
    content: '';
    display: block;
  }
}
