@import "@/scss/_global";

.lerf-email-additional-file-picker {
  margin-bottom: 0.5rem;

  h3 {
    font-size: 1.4em;
    margin: 0;
  }
}
.position-images {
  h6 {
    margin: 10px 0 15px 0;
  }
  .multiple-picker {
    .checkbox {
      position: absolute;
      z-index: 10000001;
      background: white;
      width: 20px;
      height:20px;
      .el-checkbox {
        width: initial;
        margin: 0;
        display: initial;
      }
      .el-checkbox__label {
        display: none;
      }
    }
  }
}
