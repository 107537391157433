@import "@/scss/_global";

::v-deep .el-loading-mask {
  z-index: 1;
}
.media-item {
  display: inline-block;
  margin: 5px 10px 5px 0px;
  padding: 2px;
  border: 1px solid #ddd;
  cursor: pointer;

  img {
    display: block;
  }

  &.loading {
    min-height: 100px;
    min-width: 100px;
  }

  &.pdf {
    // color: #333;
    font-size: 11px;
    width: 150px;
    height: 100px;
    overflow: hidden;
    position: relative;
    text-align: center;

    .filename {
      display: inline-block;
      margin-top: 5px;
    }

    i {
      position: absolute;
      left: 60px;
      margin-top: 40px;
      font-size: 24px;
    }
  }
}
