@import "@/scss/_global";

#choose-subcontractor {
  .el-card__body {
    padding:10px!important;
  }
  .el-card__header {
    padding:4px 10px!important;
  }
}
