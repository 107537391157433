@import "@/scss/_global";

#sync-container {
  #sync-table {
    .template-edit {
      display: none;
      position: absolute;
      right: 0;
      top: 5px;
    }

    tr:hover {
      .template-edit {
        display: inline-block;
      }
    }
  }
  .status-tag {
    color:white;
    display: block;

    &.el-tag--success {
      background-color: #b3e19d;
      color: #606266;
    }
    &.el-tag--danger {
      background-color: #F56C6C;
    }
    &.el-tag--primary {
      background-color: #409EFF;
    }
    &.el-tag--warning {
      background-color: #fd8200;
    }
  }
}
