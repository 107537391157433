@import "@/scss/_global";

.key,
.value {
  height: 36px;
  line-height: 36px;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:hover {
    background: rgba(15, 126, 190, 0.2);
  }
}

.el-radio {
  margin-left: 0px;
  margin-right: 0px;
  line-height: 1.4;
  white-space: normal;
  padding:5px;
}

.template {
  display: flex;
  align-items: center;
}

.templates {
  .el-button + .el-button {
    margin-left: 4px;
  }
}
.hidden {
  display:none;
}
