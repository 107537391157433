@import "@/scss/_global";

.evergabe {
  .start {
    width: 100%;
  }

  .screenshot {
    width: 100%;
  }

  .error {
    color: red;
  }

  .success {
    color: green;
  }

  .clickable {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
