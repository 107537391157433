@import "@/scss/_global";

::v-deep .el-card__header {
  padding-top: 10px;
  height: 50px;
}

.subcategories-view {
  font-size: 12px;
  width: 706px;
}

.category-tree {
  padding: 10px;
  width: 664px;
  height: calc(100vh - 172px);
  overflow-y: auto;
}

.subcontractor-select {
  width: 300px;
}

.area-select {
  width: 100px;
  margin-left: 10px;
}

::v-deep .el-tree-node__content {
  line-height: 30px;
  height: 30px;
  display: flex;

  .el-row {
    width: 100%;
  }
}

::v-deep .el-tree-node__expand-icon {
  margin: 8px;
}

::v-deep .el-tree > .el-tree-node {
  border-top: 1px solid #324157;
  padding-top: 5px;
  margin-top: 5px;

  &:first-child {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
  }
}

::v-deep .category-node .buttons button {
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

::v-deep .category-node,
::v-deep .subcategory-node {
  width: 576px;
  display: inline-flex;

  .buttons {
    text-align: right;
  }
}
