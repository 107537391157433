@import "@/scss/_global";

::v-deep .el-table td {
  height: auto;
  padding-top: 2px;
  padding-bottom: 2px;
}

// .pagination {
//   float: right;
//   margin-top: 1rem;

//   &:after {
//     content: '';
//     clear: both;
//     display: block;
//   }
// }

// .header-action-row {
//   margin: 0.5rem 0 1rem;
// }
