@import "@/scss/_global";
@import 'global';
@import 'mixins';

body {
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: $size_h1;
}

h2 {
  font-size: $size_h2;
}

h3 {
  font-size: $size_h3;
}

h4 {
  font-size: $size_h4;
}

h5 {
  font-size: $size_h5;
}

h6 {
  font-size: $size_h6;
}

.hidden {
  display: none;
}

.form-box {
  .el-select {
    width: 100%;
  }
}

.el-table {
  // To prevent the table being displayed over the top bar
  z-index: z($z_indices, table);
}

.status-one {
  background-color: rgba(1, 255, 112, 0.1) !important;
}

.status-two {
  background-color: rgba(127, 219, 255, 0.1) !important;
}

.status-three {
  background-color: rgba(0, 116, 217, 0.1) !important;
}

.status-four {
  background-color: rgba(255, 133, 27, 0.1) !important;
}

.status-five {
  background-color: rgba(0, 31, 63, 0.1) !important;
}

.status-six {
  background-color: rgba(177, 13, 201, 0.1) !important;
}

.status-seven {
  background-color: rgba(46, 204, 64, 0.1) !important;
}

.status-eight {
  background-color: rgba(255, 65, 54, 0.1) !important;
}

.status-nine {
  background-color: rgba(255, 220, 0, 0.1) !important;
}

.header-action-row {
  margin: 0;
}

.header-action-row + .table-widget {
}

.header-action-row.table-widget + .header-action-row.table-widget {
  margin-top: 0.25rem;
}

.header-action-row + .wrap-table,
.header-action-row + .el-table {
  margin-top: 0.5rem;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

/** Font-Awesome **/
[class^='el-icon-fa'],
[class*=' el-icon-fa'] {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome !important;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Make search filters in el-card headers less tall
:root .el-card__header {
  padding: 4px 20px;
  height: auto;
  background: $extra-light-grey;
  line-height: 30px;
  font-size: 15px;

  input {
    height: auto;
    line-height: 20px;
  }
}

// Danger and error classes
.danger,
.error {
  color: #ff6d6d;
}
.el-button.danger {
  color: #ffffff;
}

.el-message-box.confirm-delete {
  width: 500px;

  .el-message-box__message p {
    white-space: pre-line;
  }
}

/**
Fixes for latest Element UI
 */
.pull-right {
  float: right;
}

.el-input {
  .el-input__inner {
    height: 36px;
    line-height: 1;
    padding: 3px 10px;
  }

  .el-input__icon {
    line-height: 23px;
  }
}

.el-input--prefix {
  .el-input__inner {
    text-indent: 20px;
  }
}

.el-input--prefix {
  .el-input__inner {
  }
}

:root .el-checkbox {
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #1f2d3d;
  }
}

:root {
  .el-form-item {
    margin-bottom: 15px;
  }
  .el-form--label-top {
    .el-form-item {
      margin-bottom: 20px;
    }
    .el-form-item__label {
      line-height: 1.5px;
    }
  }

  .el-tabs__content {
    overflow: visible;
  }
  .form-box {
    .el-form-item {
      margin-bottom: 0px;
    }
  }
  .form-box,
  .project_field,
  .header-action-row.table-widget {
    border: 1px solid #ebeef5;
    background-color: #fafafa;
    margin: 0.5rem 0;
    padding: 0.5rem;
  }

  .form-box,
  .header-action-row.table-widget,
  .el-table,
  .project_field,
  .el-card.is-always-shadow,
  .el-card.is-hover-shadow:focus,
  .el-card.is-hover-shadow:hover {
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 10%);
  }
  .el-card .el-table,
  .el-card .project_field {
    box-shadow: none;
  }

  .el-button,
  .el-input__inner,
  .el-card {
    border-radius: 0;
  }

  .header-action-row {
    .search {
      display: flex;
      flex-wrap: wrap;

      h4 {
        margin: 0;
        min-width: 140px;
        font-size: 15px;
        color: $black;
      }

      > * {
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin: 0;
      }

      .el-date-editor,
      .el-input,
      .el-select,
      .el-checkbox {
        margin: 5px 10px 5px 0;
      }

      .el-checkbox {
        margin-right: 30px;
      }

      .el-form-item {
        margin: 0;
      }
    }
  }

  .el-collapse-item__arrow {
    order: -1;
    font-weight: bold;
    font-size: 16px;
  }

  .el-submenu__icon-arrow {
    font-weight: bold;
    font-size: 16px;
  }

  .el-button {
  }

  .el-button.el-button--small {
    padding: 7px 9px;
    font-size: 12px;
    border-radius: 0;
  }

  .el-button.el-button--mini {
    padding: 4px;
    font-size: 12px;
    border-radius: 0;
  }

  .el-table {
    .caret-wrapper {
      height: 23px;
    }

    .el-table__column-filter-trigger {
      height: 23px;
    }

    .sort-caret.ascending {
      top: 0px;
    }

    .sort-caret.descending {
      bottom: 0px;
    }

    .is-right.el-table__cell .cell {
      display: flex;
      justify-content: right;
      align-items: flex-end;
      text-align: right;
    }
    .el-button {
      margin: 0;
    }
    .el-button + .el-button {
      margin-left: 5px;
    }
  }

  .el-pagination {
    float: right;
    margin-top: 1rem;

    .el-pagination__sizes {
      margin-top: -5px;

      .el-input .el-input__inner {
        height: 28px !important;
      }
    }

    &:after {
      content: '';
      clear: both;
      display: block;
    }
  }

  .el-table .el-table__cell {
    /** TODO STYLETEST
    padding: 0;
    height: 32px;
    line-height: 32px;
    */
  }

  .el-table th.el-table__cell,
  th.el-table__cell .cell,
  .el-table thead.is-group th.el-table__cell {
  }

  .el-table .cell,
  .el-table--border .el-table__cell:first-child .cell {
    /** TODO STYLETEST
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    height: 32px;
    line-height: 32px;
    white-space: nowrap;
     */
  }

  .el-table__footer-wrapper thead th,
  .el-table__header-wrapper thead th {
    background: #f5f7fa;
  }

  .el-table__footer-wrapper thead div,
  .el-table__header-wrapper thead div {
    color: $black;
  }

  ::v-deep .caret-wrapper {
    position: absolute;
    top: 0;
    right: 5px;
  }

  .el-table th.el-table__cell > .cell {
    /** TODO STYLETEST
    white-space: nowrap;
     */
  }

  .el-table .sort-caret.ascending {
    border-bottom-color: #a7b8d9;
  }
  .el-table .sort-caret.descending {
    border-top-color: #a7b8d9;
  }
  .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #657080;
  }
  .el-table .descending .sort-caret.descending {
    border-top-color: #657080;
  }


  .el-tag {
    height: 23px;
    line-height: 23px;
    border: none;
    color: #ffffff;
    /*
    border-color: transparent;
    background: #8391a5;

    height: 23px;
    line-height: 23px;
    */
    border-radius: 0;
  }
  .el-tag.el-tag--light {
    color: $black;
  }
}

:root {
  .el-table--mini .el-table__cell {
    padding: 4px 0;
  }

  .el-table--mini th.el-table__cell {
    padding: 7px 0;
  }
}

.projects-table {
  tr {
    cursor: pointer;
  }
}
:root {
  .mapboxgl-popup-content {
    padding: 3px 20px 3px 3px;
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 10%);
  }
}
