@import "@/scss/_global";

.actions {
  .el-button {
    margin: 0;
  }

  display: inline-block;
  text-align: right;
  padding-right: 5px;

  > i {
    visibility: hidden;

    &.item-has-issue {
      visibility: visible;
    }
  }
}

.action-save {
  padding: 4px 20px;
  margin-right: 0px;
}

.action-cancel {
  padding: 4px 10px;
  margin-right: 20px;
}

.el-icon-delete {
  color: red;
  padding-left: 15px;
}

li.pricing-item span {
  word-break: break-word;
}
li.pricing-item:not(.editing):hover {
  cursor: pointer;

  .action-edit {
    visibility: visible;
  }
}

li.editing {
  ::v-deep .el-input {
    padding-right: 0px;
    .el-input__inner {
      height:24px;
    }
  }

  ::v-deep .el-select {
    padding-right: 0px;

    .el-input {
      padding-right: 0;

      i {
        margin-right: 10px;
      }
    }
  }

  ::v-deep input {
    margin-left: 0;
    background: white;
    width: calc(100% - 10px);
    padding-left:5px!important;
  }

  .actions .action-edit {
    display: none;
  }
}

::v-deep .el-input__inner {
  background: inherit;
  height: inherit;
}
