@import "@/scss/_global";

.note-history {
  ::v-deep .el-collapse-item__arrow {
    margin: 0 5px 0 0;
    font-weight: bold;
    font-size: 16px;
  }
  ::v-deep .el-collapse-item > div > div {
    padding-left: 15px;
    padding-right: 15px;
  }
}
